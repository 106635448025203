.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.post-container {
  display: flex;
  flex-direction: column;
}

.post-wrapper {
  margin: 15px 0;
  /* This adds vertical space between cards */
}

.post {
  border: 1px solid var(--posts-border-color);
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  padding: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.post-body {
  color: black;
  width: 100%;
  text-align: left;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-desc {
  color: black;
  width: 100%;
  padding: 5px 0;
  text-align: left;
  white-space: break-spaces;
  font-size: smaller;
}

.post-hashtag {
  color: blue;
  padding: 5px 0 0;
  font-weight: 600;
  font-style: italic;
  font-size: smaller;
}

.post-img-container {
  margin: 20px 0;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
}

.post-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.post-footer {
  text-align: left;
  padding: 10px 0 20px 10px;
  font-size: small;
}

.separator {
  width: var(--posts-width);
  border-bottom: 1px solid var(--posts-border-color);
}

.profiles-container {
  width: var(--posts-width);
}

.profiles-container {
  margin: 0 auto;
  /*transform: rotateX(180deg);*/
}

:root {
  --posts-width: 700px;
  --posts-border-color: lightgray;
}

.strategy-input-wrapper {
  width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;

}

.strategy-input {
  width: 400px;
  background-color: #fff;
  border-radius: 12px;
  padding-left: 140px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  height: 40px;
}

.strategy-btn-wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.strategy-btn {
  cursor: pointer;
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 12px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 15px;
  height: 40px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.active-strategy-btn {
  color: white;
  font-weight: bold;
  background-color: rgb(51 120 233);
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 100000;
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  background-color: lightgrey;
}

@media (max-width: 768px) {
  .profiles-container {
    width: 100%;
  }

  .strategy-btn-wrapper {
    flex-direction: column;
    gap: 0.5rem;
    min-width: 25%;
  }
}